import request from '@/utils/request'

const api = {
  modelList: '/arcade/model/list',
  machineList: '/arcade/machine/list',
  machineUpdate: '/arcade/machine/update',
  machineDelete: '/arcade/machine/delete',
  placeList: '/arcade/place/list',
  placeListAll: '/arcade/place/listAll',
  placeUpdate: '/arcade/place/update',
  placeDelete: '/arcade/place/delete',
  keyChipVerificationGet: '/arcade/keychipVerification/get',
  keyChipVerificationUpdate: '/arcade/keychipVerification/update'
}

export default api

export function getModelList (parameter) {
  return request({
    url: api.modelList,
    method: 'get',
    params: parameter
  })
}

export function getMachineList (parameter) {
  return request({
    url: api.machineList,
    method: 'get',
    params: parameter
  })
}

export function saveMachine (parameter) {
  return request({
    url: api.machineUpdate,
    method: 'post',
    data: parameter
  })
}

export function deleteMachine (parameter) {
  return request({
    url: api.machineDelete,
    method: 'post',
    data: parameter
  })
}

export function getPlaceList (parameter) {
  return request({
    url: api.placeList,
    method: 'get',
    params: parameter
  })
}

export function getPlaceListAll (parameter) {
  return request({
    url: api.placeListAll,
    method: 'get',
    params: parameter
  })
}

export function savePlace (parameter) {
  return request({
    url: api.placeUpdate,
    method: 'post',
    data: parameter
  })
}

export function deletePlace (parameter) {
  return request({
    url: api.placeDelete,
    method: 'post',
    data: parameter
  })
}

export function getKeyChipVerification (parameter) {
  return request({
    url: api.keyChipVerificationGet,
    method: 'get',
    params: parameter
  })
}

export function updateKeyChipVerification (parameter) {
  return request({
    url: api.keyChipVerificationUpdate,
    method: 'post',
    data: parameter
  })
}
