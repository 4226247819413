<template>
  <a-modal
    :title="model ? '修改机台' : '新建机台'"
    :width="640"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <!-- 检查是否有 id 并且大于0，大于0是修改。其他是新增，新增不显示主键ID -->
        <a-form-item v-show="model && model.id > 0" label="ID">
          <a-input v-decorator="['id', { initialValue: 0 }]" disabled />
        </a-form-item>
        <a-form-item label="KeyChip">
          <a-input v-decorator="['keyChipId', {rules: [{required: true}]}]" />
        </a-form-item>
        <a-form-item label="地点">
          <a-select v-decorator="['placeId', {rules: [{required: true}]}]" :loading="placeList.length === 0">
            <a-select-option v-for="p in placeList" :value="p.id" :key="p.model">
              {{ p.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="型号">
          <a-select v-decorator="['modelId', {rules: [{required: true}]}]" :loading="modelList.length === 0">
            <a-select-option v-for="m in modelList" :value="m.id" :key="m.model">
              {{ m.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { getModelList, getPlaceListAll } from '@/api/arcade'

// 表单字段
const fields = ['id', 'keyChipId', 'modelId', 'placeId']

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      modelList: [],
      placeList: []
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.loadModelList()
      this.loadPlaceList()
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    loadModelList () {
      getModelList().then(models => {
        this.modelList = models
      })
    },
    loadPlaceList () {
      getPlaceListAll().then(place => {
        this.placeList = place
      })
    }
  }
}
</script>
